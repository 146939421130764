<template>
	<div>
		<v-row class="mx-0" align="center">
			<v-col class="pa-0" cols="auto">
				<div @click="mode !== 'edition' ? dialog = true : null" class="paragraph cursor-pointer text-capitalize">{{ new_val ? time === '' ? $moment.duration(new_val, unit.value).humanize() : $moment.duration(time, unit.value).humanize() : $t('single_trad.DurationField.no_duration') }}</div>
			</v-col>
			<v-col class="py-0 pr-0 pl-1" cols="auto" v-if="mode !== 'edition' && mode !== 'readonly'">
				<v-btn x-small icon color="primary" @click="dialog = true">
					<v-icon small>mdi-pencil</v-icon>
				</v-btn>
			</v-col>
		</v-row>

		<Dialog v-model="dialog" :title="$t('single_trad.DurationField.edit_title')" @close="closeDialog()" :width="450">
			<template v-slot:content>
				<v-row class="mx-0 pa-3">
					<v-col cols="8" class="pr-0">
						<TextField :placeholder="$t('single_trad.DurationField.placeholder')" v-model.number="time" type="number" classes="text-right" clearable />
					</v-col>
					<v-col>
						<Select v-model="unit" :items="units" :label="$t('single_trad.DurationField.unit')" itemValue="value" :attach="false" />
					</v-col>
				</v-row>
				<v-row class="mx-0 px-3 pb-3">
					<v-col class="pr-0">
						<Button block dark color="celldark" :click="() => closeDialog()" :label="$t('global.action.cancel')" />
					</v-col>
					<v-col>
						<Button block color="primary" :click="() => {dialog = false; mode === 'edition' ? $emit('save') : null}" :label="mode === 'edition' ? $t('global.action.save') : $t('global.action.validate')" />
					</v-col>
				</v-row>
			</template>
		</Dialog>
	</div>
</template>

<script>
	export default {
		name: "DurationField",
		props: ['value', 'edit', 'mode'],
		components: {
			Dialog: () => import('@/components/ui/Dialog.vue'),
			Button: () => import('@/components/ui/Button.vue'),
			TextField: () => import('@/components/fields/TextField.vue'),
			Select: () => import('@/components/fields/Select.vue')
		},
		data(){
			return {
				dialog: false,
				unit: {
					display_name: this.$t('single_trad.DurationField.units.seconds'),
					value: 'seconds'
				},
				time: '',
				units: [
					{
						display_name: this.$t('single_trad.DurationField.units.seconds'),
						value: 'seconds'
					},
					{
						display_name: this.$t('single_trad.DurationField.units.minutes'),
						value: 'minutes'
					},
					{
						display_name: this.$t('single_trad.DurationField.units.hours'),
						value: 'hours'
					},
					{
						display_name: this.$t('single_trad.DurationField.units.days'),
						value: 'days'
					},
					{
						display_name: this.$t('single_trad.DurationField.units.months'),
						value: 'months'
					},
					{
						display_name: this.$t('single_trad.DurationField.units.years'),
						value: 'years'
					}
				]
			}
		},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		},
		watch: {
			edit(val) {
				this.dialog = val
				this.time = this.new_val

				if(!val) {
					this.unit = {
						display_name: this.$t('single_trad.DurationField.units.seconds'),
						value: 'seconds'
					}
				}
			},
			time(val) {
				this.emitVal(val)
			},
			unit() {
				this.emitVal(this.time)
			}
		},
		methods: {
			closeDialog(){
				this.dialog = false
				this.$emit('close')
			},
			emitVal(val){
				if(val === null){
					this.$emit('input', 0)
					return
				}

				if(isNaN(val)){
					return
				}

				if(this.unit.value === 'minutes'){
					this.$emit('input', val * 60)
				}
				else if(this.unit.value === 'hours'){
					this.$emit('input', val * 3600)
				}
				else if(this.unit.value === 'days'){
					this.$emit('input', val * 24 * 3600)
				}
				else if(this.unit.value === 'months'){
					this.$emit('input', val * 24 * 3600 * 30)
				}
				else if(this.unit.value === 'years'){
					this.$emit('input', val * 24 * 3600 * 365)
				}
				else {
					this.$emit('input', val)
				}
			}
		}

	}
</script>

<style scoped>

</style>